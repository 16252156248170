const Container = ({ children }: any) => (
  <div className="container md:px-10 sm:px-7 px-6">{children}</div>
)

const Section = ({
  children,
  dark,
  skipPadding,
  partialWidth
}: {
  children: any
  dark?: boolean
  skipPadding?: boolean
  partialWidth?: boolean
}) => {
  const content = partialWidth ? <div className="flex sm:w-2/3 md:w-2/4">{children}</div> : children

  return (
    <div
      className={`${skipPadding ? '' : 'pt-7 pb-7 sm:pt-20 sm:pb-20'} ${dark ? 'bg-lightGray' : ''
        }`}
    >
      <Container>{content}</Container>
    </div>
  )
}

export default Section
