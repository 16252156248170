import { useState } from 'react'
import { useRouter } from 'next/router'
import Link from './Link'
import PrimaryButton from './PrimaryButton'
import Section from './Section'
import paths from '../path'

const navigationItems = [
  { title: 'Inhalte', path: paths.product },
  { title: 'VR Therapie', path: paths.therapy },
  { title: 'Evidenz', path: paths.science },
  { title: 'Über uns', path: paths.about },
]

const contactItem = { title: 'Kontakt', path: paths.contact }

const Header = () => {
  const router = useRouter()
  const currentRoute = router.pathname

  const [isOpen, setIsOpen] = useState(false)

  const desktopNav = (
    <div className="flex items-center lg:space-x-10 space-x-6">
      <Link href="/">
        <img
          className="h-10 cursor-pointer"
          src="/neomentoLogo.svg"
          alt="Neomento logo"
        />
      </Link>
      {navigationItems.map(({ title, path }) => (
        <Link href={`${path}`} key={`header-desktop-link-${path}`}>
          <a className={`${currentRoute === path ? 'font-bold' : ''}`}>{title}</a>
        </Link>
      ))}
      <div className="flex-grow"></div>
      <PrimaryButton title={contactItem.title} href={contactItem.path} />
    </div>
  )

  const mobileNav = (
    <>
      <div className="flex my-4">
        <Link href="/">
          <img className="h-8" src="/neomentoLogo.svg" alt="Neomento logo" />
        </Link>
        <div className="flex-grow"></div>
        <button onClick={() => setIsOpen(!isOpen)}>
          <img src={isOpen ? '/menuClose.svg' : '/menuOpen.svg'} alt="Menu" />
        </button>
      </div>
      {isOpen && (
        <div className="absolute w-full left-0 right-0 bg-white z-10">
          <Section skipPadding>
            <div className="space-y-6 pt-12 py-4 leading-6">
              {navigationItems.map(({ title, path }) => (
                <Link href={`${path}`} key={`header-mobile-link-${path}`}>
                  <a className={`block ${currentRoute === path ? ' font-bold' : ''}`}>{title}</a>
                </Link>
              ))}
              <PrimaryButton
                title={contactItem.title}
                href={contactItem.path}
              />
            </div>
          </Section>
        </div>
      )}
    </>
  )

  return (
    <nav className="text-blackGray font-sans font-medium text-sm leading-4">
      <div className="hidden md:block py-8">{desktopNav}</div>
      <div className="md:hidden">{mobileNav}</div>
    </nav>
  )
}

export default Header
