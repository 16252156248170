import Image from 'next/image'
import { useState } from 'react'

import mittelpunktPic from '/public/product/scenarios/mittelpunkt.png'
import sozialPic from '/public/product/scenarios/social.png'
import alltagsherausforderungenPic from '/public/product/scenarios/alltagsherausforderungen.png'
import aggressionPic from '/public/product/scenarios/aggression.png'
import verkehrsmittelPic from '/public/product/scenarios/verkehrsmittel.png'
import raumePic from '/public/product/scenarios/raume.png'
import platzePic from '/public/product/scenarios/platze.png'
import complexeRaumePic from '/public/product/scenarios/complexeRaume.png'
import stressPic from '/public/product/scenarios/stress.png'
import erweiterungPic from '/public/product/scenarios/erweiterung.png'

import angstPic from '/public/product/applications/angst.jpeg'
import agoraphobiePic from '/public/product/applications/agoraphobie.jpeg'
import depressionPic from '/public/product/applications/depression.jpeg'
import ptbsPic from '/public/product/applications/ptbs.jpeg'
import kompetenzPic from '/public/product/applications/kompetenz.jpeg'

const faq = {
  title: 'Häufig gestellte Fragen',
  items: [
    {
      title: 'Was bekomme ich, wenn ich mich für neomento entscheide?',
      description:
        'neomento liefert ein Komplettpaket aus Hard- und Software. Außerdem möchten wir Sie bei der Implementierung unterstützen. Neben der Installation und einem professionellen Kundenservice ist daher auch eine Einschulung im Leistungsumfang enthalten.',
    },
    {
      title:
        'Ab welchem Alter ist eine Behandlung mit VR-Therapie sinnvoll?',
      description:
        'Der Einsatz von Virtual Reality als therapeutisches Instrument eignet sich ab dem Jugendalter. PatientInnen sollten nicht jünger als zwölf Jahre sein. Das hängt zum einen mit der Hardware, wie dem Gewicht der VR-Brille zusammen. Was das Material der neomento VR-T betrifft, lassen sich ab der Mittelstufe fast alle Inhalte sinnvoll einsetzen.',
    },
    {
      title:
        'Wie sicher sind die Daten, die bei einer VR-Sitzung entstehen?',
      description:
        'Als zertifiziertes Medizinprodukt muss die neomento Software hohen Privatsphärestandards genügen. Die während einer Session generierten Daten werden stark verschlüsselt und ausschließlich lokal auf Ihrem PC gespeichert. Eine Verbindung zum Internet ist für den Betrieb des Therapiesystems nicht notwendig.',
    },
    {
      title: 'Warum brauche ich einen Computer?',
      description:
        'VR-Therapie soll das Spektrum verfügbarer Behandlungen erweitern, deren Anwendung erleichtern und dem Therapeuten mehr Kontrolle während einer Exposition verleihen. Statt PatientIn und TherapeutIn voneinander zu trennen, sollen besonders vielseitige, passende und beeindruckende Erlebnisse im Behandlungszimmer entstehen. Das setzt voraus, dass TherapeutInnen die virtuellen Inhalte über den Therapie-PC miterleben und steuern können.',
    },
    {
      title: 'Wie groß muss ein Raum für VR-Therapie sein?',
      description:
        'Ein VR-T-Raum unterteilt sich in zwei Bereiche. Zunächst benötigen Sie eine Bewegungsfläche für Ihre PatientInnen. Dafür sollten zumindest 5m² zur Verfügung stehen. Zusätzlich sollten Sie ca. 3m² Platz für den TherapeutInnen-Arbeitsplatz einplanen. Der Raum kann weiterhin als Gruppen- oder Behandlungszimmer verwendet werden.',
    },
    {
      title:
        'Wer hilft mir, wenn ich Probleme mit der Anwendung haben sollte?',
      description:
        'Generell sind Software und Hardware sehr gut aufeinander abgestimmt und funktionieren zuverlässig. Sollte ein Defekt am PC oder dem VR-System auftreten, ist unser Servicepartner und VR-Technologie-Experte Schenker für Sie da. Alle Geräte sind mit einer 3-jährigen Garantie ausgestattet. Bei Fragen zur Therapie-Software können Sie unsere Hilfe auf unserer Homepage finden und uns während unserer Bürozeiten telefonisch oder per Mail erreichen.',
    },
    {
      title: 'Wie lange dauert eine Session?',
      description:
        'Die Länge einer VR-Therapiesitzung hängt vom Fortschritt der Behandlung, den Störungsbildern sowie den Bedürfnissen Ihrer PatientInnen ab. In der Regel sollten Sie für eine Session mit VR-Therapie im Mittel zwanzig Minuten einplanen.',
    },
    {
      title: 'Wie sicher ist der Einsatz von Virtual Reality?',
      description:
        'Der Einsatz von Virtual Reality in der Therapie gilt grundsätzlich als unbedenklich. Die neomento Software ist als Medizinprodukt der Klasse 1 zertifiziert. Es gibt einige wenige Kontraindikationen für das Verwenden von Virtual Reality (z.B. Epilepsie). In äußerst seltenen Fällen kann es zu Übelkeit und starkem Schwindel kommen (motion sickness). Unsere Software ist allerdings so gestaltet, dass dies äußerst selten auftritt (Teleportationsmodus).',
    },
    {
      title:
        'Kann ich die Geräte reinigen, um Infektionsrisiken zu minimieren?',
      description:
        'Für einen hygienischen Einsatz des Systems stehen Ihnen unterschiedliche Maßnahmen zur Verfügung. Zunächst können Sie Einmalmasken verwenden, die sich nach jeder Nutzung erneuern lassen. Zusätzlich lassen sich alle Oberflächen der VR-Komponenten desinfizieren.',
    },
    {
      title: 'Wozu kann ich das Therapiesystem noch verwenden?',
      description:
        'Auf dem Computer ist ein handelsübliches Windows-Betriebssystem installiert. Sie können es also auch für Ihre Praxissoftware oder Office-Programme nutzen. Weitere VR-Software können Sie über die Spieleplattform Steam erwerben. ',
    },
    {
      title:
        'Wie kommuniziere ich während der VR-Session mit meinen PatientInnen?',
      description:
        'Prinzipiell können Sie während der Sitzung mit Ihren PatientInnen sprechen. Allerdings möchten wir in einer VR-Therapiesitzung auch erreichen, dass sich PatientInnen vom Therapiezimmer lösen können und so tief wie möglich in die virtuelle Umgebung eintauchen. Dies stärkt therapeutische Effekte wie z.B. das Erleben von Selbstwirksamkeit. Aus diesen Gründen können Sie nach einer ausführlichen persönlichen Einführung vor allem über die Interaktionen der Software selbst mit Ihren PatientInnen interagieren (z.B. Dialoge). ',
    },
  ],
}

const scenarios = {
  title: 'neomento VR-Szenarien',
  items: [
    {
      title: 'Mittelpunktszenario',
      description: 'PatientInnen können verschiedene Vorträge halten (auch eigene Vorträge sind möglich) und müssen sich dabei Kritik und Fragen des virtuellen Publikums stellen. Umgang mit Ängsten, Vertiefung psychoedukativer Inhalte, Steigerung der Compliance oder Erleben von Selbstwirksamkeit. Der virtuelle Vortrag ist ein wertvoller und vielseitiger Therapiebaustein.',
      imgSrc: mittelpunktPic
    },
    {
      title: 'Soziale Interaktion',
      description: 'Alle Dialoge in unserer Software wurden von professionellen Schauspielern eingesprochen und spielen in den Trainings- und Konfrontationserlebnissen eine wichtige Rolle. Soziales Verhalten, Kognitionen, Emotionen und Affekte werden gemeinsam mit dem Therapeuten erfahrbar gemacht.',
      imgSrc: sozialPic
    },
    {
      title: 'Alltagsherausforderungen',
      description: 'Alltägliche Situationen verlangen im Grunde viel von uns. Für viele Menschen sind sie unangenehm und schwer zu bewältigen. Ein gesunder Alltag scheint kaum vorstellbar. Mit der neomento VR-Therapie lassen sich diese Situationen immer wieder aufrufen, trainieren und verändern.',
      imgSrc: alltagsherausforderungenPic
    },
    {
      title: 'Umgang mit Aggression',
      description: 'Wie gehe ich mit aggressiven, ungerechten oder unangenehmen Erlebnissen um? Wie bewerte ich die Situation richtig? Wie kann ich die Situation für mich abschließen? In VR können Sie den gesunden Umgang mit diesen Erlebnissen gemeinsam gestalten.',
      imgSrc: aggressionPic
    },
    {
      title: 'Öffentliche Verkehrsmittel',
      description: 'Für viele Menschen ein besonders aufwühlendes Erlebnis. Diese Situation im Therapieraum erleben und gemeinsam üben zu können ist ein Vorzeigebeispiel der Vorteile von in virtuo Therapie. Innerhalb der neomento Software wird dieses virtuelle Erlebnis besonders realistisch simuliert.',
      imgSrc: verkehrsmittelPic
    },
    {
      title: 'Realistische virtuelle Räume und Kulissen',
      description: 'Bestimmte Räume und Situationen können individuelle Ängste aufrufen. In VR können Sie Ereignisse, Geräusche, Dauer und Intensität steuern und so den gesunden Umgang mit Emotionen und Ängsten trainieren. ',
      imgSrc: raumePic
    },
    {
      title: 'Öffentliche Plätze',
      description: 'Ängste und Paniksymptome im öffentlichen Raum bewältigen lernen. Auch hier können zahlreiche Interaktionen und Geräusche das Erlebnis unterschiedlich wirken lassen. Soll die Stadt z.B. leer oder belebt sein? Als TherapeutIn können Sie exakt steuern, wie sich das Erlebnis offenbart und entwickelt.',
      imgSrc: platzePic
    },
    {
      title: 'Komplexe öffentliche Räume',
      description: 'Ob laut oder leise, leer oder überfüllt, aggressives oder neugieriges Verhalten, undurchsichtige Automaten oder ungerechte Autoritäten. All diese Erlebnisse können bewegen und prägen. In VR lassen sie sich individualisieren und einfach üben. ',
      imgSrc: complexeRaumePic
    },
    {
      title: 'Umgang mit Stress',
      description: 'Sind Attributionen, Kognitionen, Verhalten und Selbstwahrnehmung adäquat oder tragen Sie zur Aufrechterhaltung und Förderung bestimmter Symptome bei? Explorieren und erarbeiten Sie geeignete und gesundheitsfördernde Muster.',
      imgSrc: stressPic
    },
    {
      title: 'Ständige Erweiterung ',
      description: 'neomento ist ein Projekt mit dem Ziel der Verbesserung psychotherapeutischer Qualität und Versorgung. Wir entwickeln ständig neue Inhalte und Funktionen. Kontaktieren Sie uns und fragen Sie nach Kooperations- und Unterstützungsansätzen.',
      imgSrc: erweiterungPic
    },
  ]
}

const applications = {
  title: 'Anwendungsgebiete',
  items: [
    {
      title: 'Soziale Angst',
      description: 'Virtuelle Szenarien haben sich als äußerst wirksam darin erwiesen, soziale Angst auszulösen. Mit VR-Therapie können Sie unterschiedliche interpersonelle Kontexte herstellen, in denen sich PatientInnen exponieren müssen. Soziale Phobie zeigt sich in der Befürchtung vor anderen peinlich oder lächerlich zu wirken. Oft tritt sie auf, wenn es darum geht, etwas in der Öffentlichkeit zu leisten. Viele Menschen werden dabei zuerst an einen Vortrag oder eine Präsentation denken. Doch auch wenn wir mit Fremden, Autoritäten oder einem attraktiven Gegenüber in Kontakt treten, kommt die Angst auf. VR kann Leistungs- und Bewertungskontexte mit einer hohen Erfahrungsdichte erzeugen.Insbesondere Vortragssituationen, geskriptete Interaktionen(Schalter, Polizeikontrolle) und das Steuern des Blickkontakts sind hier wertvolle Instrumente.',
      imgSrc: angstPic
    },
    {
      title: 'Agoraphobie (mit und ohne Panikstörung)',
      description: 'Die Wirksamkeit von virtueller Exposition geht Hand in Hand mit der Dominanz des Visuellen menschlicher Wahrnehmung. Die herausragende Fähigkeit von VR, alle erdenklichen Räume zu erschaffen, erstreckt sich auch auf die öffentliche Sphäre. Personen, die von Agoraphobie oder Platzangst betroffen sind, fürchten sich vor großen Plätzen, Menschenansammlungen, engen Räumen und Verkehrsmitteln. Oft tritt sie gepaart mit einer Panikstörung auf. In Folge der Angst werden öffentliche Räume gemieden, im schlimmsten Fall die eigenen vier Wände überhaupt nicht mehr verlassen. neomento VR-T bietet Ihnen unterschiedliche Räume zur Behandlung der Agoraphobie. Unter anderem beinhalten die Szenarien: einen Aufzug, einen weiten, öffentlichen Platz, einen Bahnsteig und eine befahrbare U-Bahn. In der neomento VR-Therapiesoftware können Sie die Anzahl der Personen und Interaktionen, sowie deren Eigenschaften und Entwicklung festlegen und steuern.',
      imgSrc: agoraphobiePic
    },
    {
      title: 'Depression',
      description: 'Depressionen sind begleitet von Gefühlen der Niedergeschlagenheit, einem Interessens- und Sinnverlust, Gedanken der eigenen Minderwertigkeit sowie somatischen Funktionsstörungen. Anders als eine Stimmungsschwankung dauern sie über einen längeren Zeitraum an. Expositionsbasierte Verfahren gewinnen auch in der Behandlung von Depressionen zunehmend an Bedeutung. Denn auch bei der Aufrechterhaltung affektiver Störungen spielen Vermeidung und Attribution von Erfahrungen und Gefühlen eine wichtige Rolle. Virtuelle Szenarien helfen dabei, Kontexte herzustellen, in denen z.B. kritisierende Aspekte des Selbstkonzepts aktiviert werden. Insbesondere Szenen der sozialen Exposition mit virtuellen Charakteren können dazu genutzt werden, interpersonelles Vermeidungsverhalten von PatientInnen zu therapieren.',
      imgSrc: depressionPic
    },
    {
      title: 'Posttraumatische Belastungsstörung (PTBS)',
      description: 'Ein traumatisches Erlebnis steht im Zusammenhang mit einer Bedrohung der psychischen und körperlichen Unversehrtheit. Als Folgeerscheinungen zeigt sich bei Betroffenen ein intrusives Wiedererleben des Traumas, Vermeidungsverhalten in Bezug auf assoziierte Reize sowie körperliches Hyperarousal. Dabei spielt Vermeidung eine zentrale Rolle bei der Aufrechterhaltung der Symptome. Die Expositionstherapie zielt darauf ab, die Furchtstruktur von PatientInnen zu aktivieren und in der Folge zu hemmen oder gar zu löschen. Virtuelles Stimulusmaterial kann in der Behandlung dazu verwendet werden, sich an traumaassoziierte “hot spots” anzunähern. Beklemmende Orte wie ein nächtlicher, menschenleerer Platz, ein abweisender Gang in einem Parkhaus oder eine U-Bahn-Station bieten sich an dieser Stelle an.',
      imgSrc: ptbsPic
    },
    {
      title: 'Kompetenz-Training',
      description: 'Sich selbst in sozialen Situationen als kompetent zu erleben, gilt als wichtiger Gegenspieler zu Ängsten und Depression. Dementsprechend steht ein Mangel an sozialen Fertigkeiten mit einer Vielzahl an psychischen Störungsbildern in Verbindung. Gleichzeitig bilden diese auch eine wichtige Grundlage, um Verfahren wie soziale Expositionsbehandlungen mit PatientInnen anzuwenden. Soziales Kompetenztraining kann an unterschiedlichen Stellen ansetzen, etwa bei Selbstverbalisation, Selbstwahrnehmung, Bewältigungsstrategien oder dem Aufbrechen sozialen Rückzugs. Virtuelle Kontexte können dabei von großem Nutzen sein, denn sie können soziale Interaktion realistisch nachstellen und sind jederzeit verfügbar und kontrollierbar. Die neomento VR-Therapiesoftware gibt Ihnen Zugang zu Alltagsinteraktionen sowie Austausch- und Leistungssituationen in denen Sie Diskriminations-, Kompetenz- und Konfliktübungen mit Ihren PatientInnen durchführen können.',
      imgSrc: kompetenzPic
    },
  ]
}

const AccordionItem = ({ item, active, onClick }: { item: any, active: boolean, onClick: any }) => {
  const { title, description, imgSrc = '' } = item

  return (
    <li
      className={`${active ? 'active' : ''
        } border-t border-footerBorder text-sm leading-4`}
    >
      <div className="py-6 cursor-pointer flex items-center" onClick={onClick}>
        <div className="font-bold ">{title}</div>
        <div className="flex-grow" />
        <img
          className='ml-5'
          src={active ? '/accordionMinus.svg' : '/accordionPlus.svg'}
          alt="Accordion item"
        />
      </div>
      <div className={active ? 'mb-8 grid grid-cols-1 lg:grid-flow-col gap-4 lg:gap-14' : 'hidden'}>
        <div className="max-w-4xl">{description}</div>
        {
          imgSrc && (
            <div className="order-first lg:order-last lg:max-w-lg w-full">
              <Image src={imgSrc} width={512} height={272} quality={30} priority />
            </div>
          )
        }
      </div>
    </li>
  )
}

const Accordion = ({ type }: { type: 'faq' | 'scenarios' | 'applications' }) => {
  const [openIndex, setOpenIndex] = useState(0)

  const onClick = (index: number) => {
    openIndex === index ? setOpenIndex(-1) : setOpenIndex(index)
  }

  const list = type === 'scenarios' ? scenarios : type === "applications" ? applications : faq

  return (
    <>
      <div className="font-bold text-lg leading-5 mb-6 text-blackGray">
        {list.title}
      </div>
      <ul className="border-b bordert-t border-footerBorder">
        {list.items.map((item, index) => (
          <div key={`${type}-${index}`}>
            <AccordionItem
              onClick={() => onClick(index)}
              active={openIndex === index}
              key={`accordion-item-${index}`}
              item={item}
            />
          </div>
        ))}
      </ul>
    </>
  )
}

export default Accordion
