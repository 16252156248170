import Link from './Link'

const PrimaryButton = ({
  title,
  href,
  type,
  fullWidth,
  skipPadding,
}: {
  title: string
  href?: string
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  skipPadding?: boolean
}) => {
  const Button = (
    <button
      className={`hover:underline font-bold py-3 rounded-full text-white bg-main cursor-pointer ${fullWidth ? 'w-full' : ''
        } ${skipPadding ? 'px-6' : 'px-10 lg:px-20'} `}
      type={type || 'button'}
    >
      {title}
    </button>
  )

  return href ? <Link href={href}>{Button}</Link> : Button
}

export default PrimaryButton
