import Head from 'next/head'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Accordion from '../components/Accordion'
import Section from '../components/Section'
import Favicon from '../components/Favicon';

const Page = ({ children, title, background, backgroundSmall, backgroundSection = null }: { children: any, title: string, background?: string, backgroundSmall?: string, backgroundSection?: any }) => (
  <div className="text-blackGray">
    <Head>
      <title>{title}</title>
      <Favicon />
    </Head>

    <main className="relative text-xs sm:text-base leading-tight sm:leading-normal">
      {background && backgroundSmall ? (
        <>
          <img src={`./backgrounds/${background}`} alt="" className="w-0 md:w-full" />
          <img src={`./backgrounds/${backgroundSmall}`} alt="" className="md:w-0 w-full" />
          <div className='absolute top-0 left-0 right-0'>
            <Section skipPadding>
              <Header />
            </Section>
            {backgroundSection}
          </div>
        </>
      ) : (
        <Section skipPadding>
          <Header />
        </Section>
      )}

      {children}

      <Section>
        <Accordion type="faq" />
      </Section>
    </main>

    <footer>
      <Section dark skipPadding>
        <Footer />
      </Section>
    </footer>
  </div >
)

export default Page
