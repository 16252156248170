import Link from './Link'
import PrimaryButton from './PrimaryButton'
import paths from '../path'

const contactItem = { title: 'neomento kontaktieren', path: paths.contact }

const findOutMoreItems = [
  { title: 'Über uns', path: paths.about },
  { title: 'Kontakt', path: paths.contact },
  { title: 'Evidenz', path: paths.science },
  { title: 'Inhalte', path: paths.product },
  { title: 'VR Therapie', path: paths.therapy },
]

const connectItems = [
  { title: 'Twitter', path: 'https://twitter.com/neomento_news', icon: 'twitter.svg' },
  {
    title: 'Linkedin',
    path: 'https://www.linkedin.com/company/neomento-vr-therapie/',
    icon: 'linkedin.svg',
  },
]

const Title = ({ children }: any) => (
  <p className="font-bold text-grayDarkLight uppercase mb-3">{children}</p>
)

const Subtitle = ({ children }: any) => (
  <p className="font-bold  text-grayDarkLight text-xs leading-normal">
    {children}
  </p>
)

const Paragraph = ({ children }: any) => (
  <p className="font-normal text-xs leading-normal max-w-4xl">{children}</p>
)

const Block = ({ title, width, children }: any) => (
  <div className={`border-t sm:border-t-0 border-footerBorder w-full sm:${width} pt-12 pb-12 sm:pt-20 sm:pb-20`}>
    <Title>{title}</Title>
    {children}
  </div>
)

export default function Footer() {
  return (
    <div className="text-grayDarkLight text-sm font-normal">
      <div className="pt-10 pb-24 sm:pt-12 sm:pb-12">
        <Subtitle>
          neomento Virtual Reality Therapie
        </Subtitle>
        <div className="flex flex-col space-y-5">
          <Paragraph>
            Das Projekt neomento entstand 2017 in Berlin und Magdeburg am
            Deutschen Zentrum für Neurodegenerative Erkrankungen und der Charité
            in Berlin.
          </Paragraph>
          <Paragraph>
            Wir nutzen bei der Entwicklung unserer Software besonders hochwertige und zukunftsorientierte Technologien und erweitern unsere Software ständig um zusätzliche Inhalte und Anwendungsoptionen. Wir glauben daran, dass eine hochwertige und wirksame Psychotherapie, insbesondere bei ausgeprägter Symptomatik, in erster Linie in der Praxis stattfinden muss und die Unterstützung des Therapeuten braucht. Daher soll neomento TherapeutInnen zur Verfügung gestellt werden und somit die therapeutischen Ressourcen um eine Komponente erweitern: Die VR-Therapie. Wir sind davon überzeugt, dass VR-Therapie in naher Zukunft zum Standard in der psychotherapeutischen Praxis wird. Ihre PatientInnen in eine virtuelle Umgebung zu entsenden und gemeinsam im geschützten Therapieraum Neues oder schwer Zugänliches zu erleben, ist ein unglaublich wertvoller Gedanke. Wir arbeiten auch daran, in Zukunft Sitzungsdaten und völlig neue Methoden in unser System zu integrieren. Ein handelsüblicher PC, der Erlebnisse verfügbar, individualisierbar und messbar macht, soll und wird der nächste Schritt moderner Psychotherapie sein. Das Projekt neomento will Sie dazu animieren, diesen Schritt gemeinsam mit uns zu gehen.
          </Paragraph>
        </div>
      </div>
      <div className="sm:pb-16 pb-32">
        <Subtitle>Unterstützt durch:</Subtitle>
        <div className="flex flex-wrap flex-row gap-x-8 gap-y-8 mt-6 sm:mt-8">
          <div>
            <img className="h-8 sm:h-11" src="/support/bmwi.svg" />
          </div>
          <div>
            <img className="h-8 sm:h-11" src="/support/dzne.svg" />
          </div>
          <div>
            <img className="h-8 sm:h-11" src="/support/helmholtz.svg" />
          </div>
          <div>
            <img className="h-8 sm:h-11" src="/support/erc.svg" />
          </div>
          <div>
            <img className="h-8 sm:h-11" src="/support/esf.svg" />
          </div>
          <div>
            <img className="h-8 sm:h-11" src="/support/exist.svg" />
          </div>
        </div>
      </div>
      <div className="border-t-0 sm:border-t border-b border-footerBorder flex flex-col sm:flex-row w-full">
        <Block title="Info:" width="w-1/4">
          <div className="flex flex-col space-y-4">
            {findOutMoreItems.map(({ title, path }) => (
              <Link href={`${path}`} key={`footer-info-${path}`}>
                <a>{title}</a>
              </Link>
            ))}
          </div>
        </Block>

        <Block title="Weblinks:" width="w-1/4">
          <div className="flex flex-col space-y-3">
            {connectItems.map(({ title, path, icon }) => (
              <div className="flex uppercase" key={`footer-link-${path}`}>
                <img className="w-4" src={`/${icon}`} alt={`${title} logo`} />
                <Link href={`${path}`} key={path}>
                  <a className="ml-3 text-sm">{title}</a>
                </Link>
              </div>
            ))}
          </div>
        </Block>

        <Block title="Kontakt:" width="w-2/4">
          <Paragraph>Buchen Sie eine Vorführung oder stellen Sie uns eine Frage. Wir freuen uns über Ihr Interesse.</Paragraph>
          <div className="mt-10 sm:mt-6">
            <PrimaryButton title={contactItem.title} href={contactItem.path} skipPadding />
          </div>
        </Block>
      </div >
      <div className="flex pt-6 pb-12 font-medium">
        <div>neomento © 2024</div>
        <div className="flex-grow"></div>
        <div className="font-normal">
          <Link href="/impressum">Impressum</Link>
        </div>
      </div>
    </div >
  )
}
