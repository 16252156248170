import NextLink from 'next/link'

const Link = ({ children, href, ...props }: any) => (
  <div className="hover:underline">
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  </div>
)

export default Link
